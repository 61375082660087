import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const StyledWrapper = styled.div`
  .form-control {
    height: 69px;
    width: 100% !important;
    background-color: #ececec;

    @media (max-width: 600px) {
      height: 50px;
    }
  }

  .form-label {
    font-family: "Nunito Sans";
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 20px;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }
  .form-check-label {
    font-size: 16px;
    font-weight: normal;
  }
  .progress {
    height: 1.3rem;
  }
  .progress-bar {
    background-color: #474cd8 !important;
  }
  .progress-bar-text {
    color: #474cd8;
    font-size: 14px;
    font-weight: bold;
  }
  .submit-form {
    height: 50px;
    width: 180px;
    background-color: #464fd0;
    @media (max-width: 600px) {
      width: 120px;
    } 
  }

  .form-check {
    margin-top: 10px;
  }

  .form-check-input {
    transform: scale(1.2);
  }

  .cancel-btn {
    font-size: 16px;
    font-weight: bold;
    color: #464fd0;
    cursor: pointer;
    position: relative;
    top: 8px;
  }
  .cancel-btn:focus{
    border:none;
    box-shadow:none;
  }
  .form-group {
    margin-bottom: 0;
  }

  .checkbox-link {
    display: inline-block;
    color: black;
    text-decoration: underline;
  }

  .fail-text {
    padding-top: 10px;
    padding-right: 80px;
    font-weight: bolder;
    color: #e43d5a;
  }

  .required-text {
    color: #e43d5a;
  }

  .autocomplete_sugestions {
    overflow: auto;
    max-height: 200px;
  }
  .h_color {
    color: #4B0082 !important;
  }
`;

const ApplyNowFourthForm = ({
  setStep,
  setProgressValue,
  commonLayoutData,
  setCommonLayoutData,
  partialDataStore,
}) => {
  const { register, handleSubmit, errors, setValue, getValues } = useForm();
  const [yearsList, setYearsList] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [getEduData, setEduData] = useState(null);
  const [mainQualification, setMainQualification] = useState([]);
  const [qualificationContent, setQualificationContent] = useState("");
  const [ibGradesList, setIbGradesList] = useState([]);
  const [error, setError] = useState("");
  const [eligibility, setEligibility] = useState(false);
  const [gcseSchoolTextSuggestions, setGcseSchoolTextSuggestions] = useState(
    null
  );
  const [aLevelInfosTextSuggestions, setALevelInfosTextSuggestions] = useState(
    null
  );
  const [
    filterUkSchoolThreeSuggestions,
    setFilterUkSchoolThreeSuggestions,
  ] = useState(null);
  const [
    filterUkSchoolFourSuggestions,
    setFilterUkSchoolFourSuggestions,
  ] = useState(null);
  const [
    filterUkSchoolFiveSuggestions,
    setFilterUkSchoolFiveSuggestions,
  ] = useState(null);
  const [aLevelCodeSuggestions, setALevelCodeSuggestions] = useState(null);
  const [homePostCodeSuggestions, setHomePostCodeSuggestions] = useState(null);
  const [mixQualitficationOne, setMixQualificationOne] = useState("");
  const [mixQualitficationTwo, setMixQualificationTwo] = useState("");
  const [mixQualitficationThree, setMixQualificationThree] = useState("");
  const [mixQualitficationFour, setMixQualificationFour] = useState("");
  const [newvalue1, setNewValue1] = useState(0);
  const [newvalue2, setNewValue2] = useState(0);
  const [newvalue3, setNewValue3] = useState(0);
  const [
    aLevelOneSubjectSuggestions,
    setALevelOneSubjectSuggestions,
  ] = useState(null);
  const [
    aLevelTwoSubjectSuggestions,
    setALevelTwoSubjectSuggestions,
  ] = useState(null);
  const [
    aLevelThreeSubjectSuggestions,
    setALevelThreeSubjectSuggestions,
  ] = useState(null);
  const enkiUrl = process.env.GATSBY_ENKI_URL;

  const gcseGrades = [
    {
        id: '9',
        name: '9'
    }, {
        id: '8',
        name: '8'
    }, {
        id: '7',
        name: '7'
    }, {
        id: '6',
        name: '6'
    }, {
        id: '5',
        name: '5'
    }, {
        id: '4',
        name: '4'
    }, {
        id: '3',
        name: '3'
    }, {
        id: '2',
        name: '2'
    }, {
        id: '1',
        name: '1'
    }, {
        id: 'DID_NOT_TAKE',
        name: 'Did not take'
    }, {
        id: 'A*',
        'name': 'Grade A*'
    }, {
        id: 'A',
        'name': 'Grade A'
    }, {
        id: 'B',
        'name': 'Grade B'
    }, {
        id: 'C',
        'name': 'Grade C'
    }, {
        id: 'D',
        'name': 'Grade D'
    }, {
        id: 'E',
        'name': 'Grade E'
    }, {
        id: 'F',
        'name': 'Grade F'
    }, {
        id: 'U',
        name: 'Grade U'
    }
];

  const aLevelGrade = [
    {
      id: "A*",
      name: "Grade A*",
    },
    {
      id: "A",
      name: "Grade A",
    },
    {
      id: "B",
      name: "Grade B",
    },
    {
      id: "C",
      name: "Grade C",
    },
    {
      id: "D",
      name: "Grade D",
    },
    {
      id: "E",
      name: "Grade E",
    },
    {
      id: "F",
      name: "Grade F",
    },
    {
      id: "U",
      name: "Grade U",
    },
    {
      id: "DID_NOT_TAKE",
      name: "Did not take",
    },
  ];
  const [heGradesListOne, setHeGradesListOne] = useState([
    "D0",
    "D3",
    "D6",
    "D9",
    "D12",
    "D15",
    "D18",
    "D21",
    "D24",
    "D27",
    "D30",
    "D33",
    "D36",
    "D39",
    "D42",
    "D45",
  ]);
  const [heGradesListTwo, setHeGradesListTwo] = useState([
    "M0",
    "M3",
    "M6",
    "M9",
    "M12",
    "M15",
    "M18",
    "M21",
    "M24",
    "M27",
    "M30",
    "M33",
    "M36",
    "M39",
    "M42",
    "M45",
  ]);
  const [heGradesListThree, setGradesListThree] = useState([
    "P0",
    "P3",
    "P6",
    "P9",
    "P12",
    "P15",
    "P18",
    "P21",
    "P24",
    "P27",
    "P30",
    "P33",
    "P36",
    "P39",
    "P42",
    "P45",
  ]);
  const x = [
    "D0",
    "D3",
    "D6",
    "D9",
    "D12",
    "D15",
    "D18",
    "D21",
    "D24",
    "D27",
    "D30",
    "D33",
    "D36",
    "D39",
    "D42",
    "D45",
  ];
  const initial_grades_1 = [
    "D0",
    "D3",
    "D6",
    "D9",
    "D12",
    "D15",
    "D18",
    "D21",
    "D24",
    "D27",
    "D30",
    "D33",
    "D36",
    "D39",
    "D42",
    "D45",
  ];
  const initial_grades_2 = [
    "M0",
    "M3",
    "M6",
    "M9",
    "M12",
    "M15",
    "M18",
    "M21",
    "M24",
    "M27",
    "M30",
    "M33",
    "M36",
    "M39",
    "M42",
    "M45",
  ];
  const initial_grades_3 = [
    "P0",
    "P3",
    "P6",
    "P9",
    "P12",
    "P15",
    "P18",
    "P21",
    "P24",
    "P27",
    "P30",
    "P33",
    "P36",
    "P39",
    "P42",
    "P45",
  ];
  const [btechMixGradesList] = useState([
    { "D*D*D*": "D*D*D*" },
    { "D*D*D": "D*D*D" },
    { "D*DD": "D*DD" },
    { DDD: "DDD" },
    { DDM: "DDM" },
    { DMM: "DMM" },
    { MMM: "MMM" },
    { MMP: "MMP" },
    { MPP: "MPP" },
    { PPP: "PPP" },
  ]);
  const [qualifications] = useState([
    { 'A_LEVEL': "A-Levels" },
    { 'B_TECH_LEVEL_3': 'BTEC' },
    { 'IB': 'IB' },
    { 'ADDITIONAL_QUALIFICATION': 'Additional qualifications' }
]);
  const y = x.map(s => s.slice(1));

  useEffect(() => {
    if (commonLayoutData) {
      setEduData(commonLayoutData?.schools_and_colleges_attended)
      setQualificationContent(commonLayoutData?.main_qualification_details?.qualification_type)
      setMixQualificationOne(commonLayoutData?.main_qualification_details?.mix_qualifications?.[0]?.qualification)
      setMixQualificationTwo(commonLayoutData?.main_qualification_details?.mix_qualifications?.[1]?.qualification)
      setMixQualificationThree(commonLayoutData?.main_qualification_details?.mix_qualifications?.[2]?.qualification)
      setMixQualificationFour(commonLayoutData?.main_qualification_details?.mix_qualifications?.[3]?.qualification)
      setEligibility(commonLayoutData?.form1_eligibility)

      setTimeout(() => {
        if (commonLayoutData.postal_code_gcse instanceof Object && document.querySelector("#postal_code_gcse")) {
          document.querySelector("#postal_code_gcse").setAttribute("data-object", JSON.stringify(commonLayoutData?.postal_code_gcse))
        }
        if (commonLayoutData.postal_code_a_level instanceof Object && document.querySelector("#postal_code_a_level")) {
          document.querySelector("#postal_code_a_level").setAttribute("data-object", JSON.stringify(commonLayoutData?.postal_code_a_level))
        }
        if (commonLayoutData.uk_school_three instanceof Object && document.querySelector("#uk_school_three") &&
          document.querySelector("#uk_school_three")) {
          document.querySelector("#uk_school_three")
            .setAttribute("data-object", JSON.stringify(commonLayoutData.uk_school_three));
        }
        if (commonLayoutData.uk_school_four instanceof Object && document.querySelector("#uk_school_four") &&
          document.querySelector("#uk_school_four")) {
          document.querySelector("#uk_school_four")
            .setAttribute("data-object", JSON.stringify(commonLayoutData.uk_school_four));
        }
        if (commonLayoutData.uk_school_five instanceof Object && document.querySelector("#uk_school_five") &&
          document.querySelector("#uk_school_five")) {
          document.querySelector("#uk_school_five")
            .setAttribute("data-object", JSON.stringify(commonLayoutData.uk_school_five));
        }

        if (commonLayoutData?.main_qualification_details && commonLayoutData.main_qualification_details.qualification_type == "A_LEVEL") {
          if (commonLayoutData?.main_qualification_details?.a_level?.title_one_object instanceof Object &&
            document.querySelector("#a_level_title_one")) {
            document.querySelector("#a_level_title_one")
              .setAttribute("data-object", JSON.stringify(commonLayoutData?.main_qualification_details?.a_level?.title_one_object));
          }
          if (commonLayoutData?.main_qualification_details?.a_level?.title_two_object instanceof Object &&
            document.querySelector("#a_level_title_two")) {
            document.querySelector("#a_level_title_two")
              .setAttribute("data-object", JSON.stringify(commonLayoutData?.main_qualification_details?.a_level?.title_two_object));
          }
          if (commonLayoutData?.main_qualification_details?.a_level?.title_three_object instanceof Object &&
            document.querySelector("#a_level_title_three")) {
            document.querySelector("#a_level_title_three")
              .setAttribute("data-object", JSON.stringify(commonLayoutData?.main_qualification_details?.a_level?.title_three_object));
          }
        }

        if (commonLayoutData.a_level_school) {
          setValue("a_level_school", commonLayoutData?.a_level_school)
        }
        if (commonLayoutData.gcse_school) {
          setValue("gcse_school", commonLayoutData?.gcse_school)
        }
      }, 1000)
    }
  }, [commonLayoutData]);

  useEffect(() => {
    document.getElementsByClassName("apply-now")[0].scrollTop = 0;
    getYearsList();
    range();
    qualificationType();
    getIBGradesList();
  }, []);

  const getYearsList = () => {
    var startYear = 1950;
    var currentYear = new Date().getFullYear();
    var yearsData = [];
    for (let i = startYear; i <= currentYear; i++) {
      yearsData.push(i);
    }
    yearsData.reverse();
    setYearsList(yearsData);
  };

  const range = () => {
    var start = 2;
    var end = 5;
    var ans = [];
    for (let i = start; i <= end; i++) {
      ans.push(i);
    }
    setRanges(ans);
  };

  const qualificationType = () => {
    var url = `${enkiUrl}/main-qualification/qualifications`;
    const requestOptions = {
      method: "GET",
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.status == "SUCCESS") {
          setMainQualification(data.mainQualifications);
        }
      });
  };

  const getIBGradesList = () => {
    var startGrade = 7;
    var endGrade = 45;
    var ibGradesData = [];
    for (let i = startGrade; i <= endGrade; i++) {
      ibGradesData.push(i);
    }
    ibGradesData.reverse();
    setIbGradesList(ibGradesData);
  };

  const filterGcseSchoolCode = function (val) {
    var url = `${enkiUrl}/ukschool/autocomplete?term=` + val;
    const requestOptions = {
      method: "GET",
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        setGcseSchoolTextSuggestions(data);
      });
  };

  const filterALevelInfos = function (val) {
    var url = `${enkiUrl}/ukschool/autocomplete?term=` + val;
    const requestOptions = {
      method: "GET",
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        setALevelInfosTextSuggestions(data);
      });
  };

  const filterUkSchoolThree = function (val) {
    var url = `${enkiUrl}/ukschool/autocomplete?term=` + val;
    const requestOptions = {
      method: "GET",
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        setFilterUkSchoolThreeSuggestions(data);
      });
  };

  const filterUkSchoolFour = function (val) {
    var url = `${enkiUrl}/ukschool/autocomplete?term=` + val;
    const requestOptions = {
      method: "GET",
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        setFilterUkSchoolFourSuggestions(data);
      });
  };

  const filterUkSchoolFive = function (val) {
    var url = `${enkiUrl}/ukschool/autocomplete?term=` + val;
    const requestOptions = {
      method: "GET",
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        setFilterUkSchoolFiveSuggestions(data);
      });
  };

  const filterALevelPostCode = function (val) {
    var url = `${enkiUrl}/postal-code/autocomplete?term=` + val;
    const requestOptions = {
      method: "GET",
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        setALevelCodeSuggestions(data);
      });
  };

  const filterHomePostCode = function (val) {
    var url = `${enkiUrl}/postal-code/autocomplete?term=` + val;
    const requestOptions = {
      method: "GET",
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        setHomePostCodeSuggestions(data);
      });
  };

  /* Auto Complete for A Level Subject */
  const filterALevelOneSubject = function (e) {
    var url = `${enkiUrl}/a-level-subject/autocomplete?term=` + e;
    const requestOptions = {
      method: "GET",
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        let results = data.filter(postCode => {
          return postCode.value.toLowerCase().startsWith(e.toLowerCase());
        });
        setALevelOneSubjectSuggestions(results);
      });
  };

  /* Auto Complete for A Level Subject */
  const filterALevelTwoSubject = function (e) {
    var url = `${enkiUrl}/a-level-subject/autocomplete?term=` + e;
    const requestOptions = {
      method: "GET",
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        let results = data.filter(postCode => {
          return postCode.value.toLowerCase().startsWith(e.toLowerCase());
        });
        setALevelTwoSubjectSuggestions(results);
      });
  };

  /* Auto Complete for A Level Subject */
  const filterALevelThreeSubject = function (e) {
    var url = `${enkiUrl}/a-level-subject/autocomplete?term=` + e;
    const requestOptions = {
      method: "GET",
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        let results = data.filter(postCode => {
          return postCode.value.toLowerCase().startsWith(e.toLowerCase());
        });
        setALevelThreeSubjectSuggestions(results);
      });
  };

  const saveForLater = (pageId, progressPercent) => {
    let data = { ...data, ...commonLayoutData };
    //save for later move to last updated page
    if (commonLayoutData.page_id > pageId) {
      data.page_id = commonLayoutData.page_id;
      data.progress_percentage = commonLayoutData.progress_percentage;
    }
    else {
      data.page_id = pageId;
      data.progress_percentage = progressPercent;
    }
    SavingDataObject(data)
    partialDataStore({ data });
  };

  const heDropdownChange = (value, grade_num) => {
    var new_value1 = newvalue1;
    var new_value2 = newvalue2;
    var new_value3 = newvalue3;
    var max_value = 45;
    if (grade_num == 1 && value != undefined) {
      new_value1 = value.split("D")[1];
      setNewValue1(new_value1);
    }
    if (grade_num == 2 && value != undefined) {
      new_value2 = value.split("M")[1];
      setNewValue2(new_value2);
    }
    if (grade_num == 3 && value != undefined) {
      new_value3 = value.split("P")[1];
      setNewValue3(newvalue3);
    }
    var result = y.map(function (x) {
      return parseInt(x);
    });

    if (grade_num == 1) {
      //To display second dropdown
      var second_temp = parseInt(new_value1) + parseInt(new_value3);
      var temp2 = max_value - second_temp;
      var index2 = result.indexOf(temp2);
      setHeGradesListTwo(initial_grades_2.slice(0, index2 + 1));
      //To display third dropdown
      var third_temp = parseInt(new_value1) + parseInt(new_value2);
      var temp3 = max_value - third_temp;
      var index3 = result.indexOf(temp3);
      setGradesListThree(initial_grades_3.slice(0, index3 + 1));
    }
    if (grade_num == 2) {
      //To display First dropdown
      var first_temp = parseInt(new_value2) + parseInt(new_value3);
      var temp1 = max_value - first_temp;
      var index1 = result.indexOf(temp1);
      setHeGradesListOne(initial_grades_1.slice(0, index1 + 1));
      //To display third dropdown
      var third_temp = parseInt(new_value1) + parseInt(new_value2);
      var temp3 = max_value - third_temp;
      var index3 = result.indexOf(temp3);
      setGradesListThree(initial_grades_3.slice(0, index3 + 1));
    }
    if (grade_num == 3) {
      //To display First dropdown
      var first_temp = parseInt(new_value2) + parseInt(new_value3);
      var temp1 = max_value - first_temp;
      var index1 = result.indexOf(temp1);
      setHeGradesListOne(initial_grades_1.slice(0, index1 + 1));
      //To display second dropdown
      var second_temp = parseInt(new_value1) + parseInt(new_value3);
      var temp2 = max_value - second_temp;
      var index2 = result.indexOf(temp2);
      setHeGradesListTwo(initial_grades_2.slice(0, index2 + 1));
    }
  };

  const SavingDataObject = (data) => {
    // main qualifications
    if (data?.main_qualification_details?.qualification_type == "A_LEVEL") {
      let a_level_title_one = document.querySelectorAll("#a_level_title_one")[0].getAttribute("data-object");
      const a_level_title_one_oject = JSON.parse(a_level_title_one)
      a_level_title_one_oject && (data.main_qualification_details.a_level.title_one_object = JSON.parse(a_level_title_one))

      let a_level_title_two = document.querySelectorAll("#a_level_title_two")[0].getAttribute("data-object");
      const a_level_title_two_oject = JSON.parse(a_level_title_two)
      a_level_title_two_oject && (data.main_qualification_details.a_level.title_two_object = JSON.parse(a_level_title_two))

      let a_level_title_three = document.querySelectorAll("#a_level_title_three")[0].getAttribute("data-object");
      const a_level_title_three_oject = JSON.parse(a_level_title_three)
      a_level_title_three_oject && (data.main_qualification_details.a_level.title_three_object = JSON.parse(a_level_title_three))

      // Saving extra a-level key-value pairs
      if (a_level_title_one_oject) {
        data.main_qualification_details.a_level["title_one"] = a_level_title_one_oject.value
        data.main_qualification_details.a_level["a_level_title_one_id"] = a_level_title_one_oject.id
      }else{
        data.main_qualification_details.a_level["title_one"] = commonLayoutData.main_qualification_details.a_level.title_one_object
      }
      
      if (a_level_title_two_oject) {
        data.main_qualification_details.a_level["title_two"] = a_level_title_two_oject.value
        data.main_qualification_details.a_level["a_level_title_two_id"] = a_level_title_two_oject.id
      }else{
        data.main_qualification_details.a_level["title_two"] = commonLayoutData.main_qualification_details.a_level.title_two_object
      }

      if (a_level_title_three_oject) {
        data.main_qualification_details.a_level["title_three"] = a_level_title_three_oject.value
        data.main_qualification_details.a_level["a_level_title_three_id"] = a_level_title_three_oject.id
      }else{
        data.main_qualification_details.a_level["title_three"] = commonLayoutData.main_qualification_details.a_level.title_three_object
      }
    }

    let postal_code_a_level = document.querySelectorAll("#postal_code_a_level")[0].getAttribute("data-object");
    if(postal_code_a_level != null && postal_code_a_level != ""){
      data.postal_code_a_level = JSON.parse(postal_code_a_level)
      data.postal_code_a_level.text = data.postal_code_a_level.value
    }else{
      data.postal_code_a_level = postal_code_a_level;
    }

    let postal_code_gcse = document.querySelectorAll("#postal_code_gcse")[0].getAttribute("data-object");
    if(postal_code_gcse != null && postal_code_gcse != ""){
      data.postal_code_gcse = JSON.parse(postal_code_gcse)
      data.postal_code_gcse.text = data.postal_code_gcse.value
    }

    let uk_school_details = []
    if (getEduData >= 3 && document.querySelectorAll("#uk_school_three")[0].getAttribute("data-object")) {
      let uk_school_three = document.querySelectorAll("#uk_school_three")[0].getAttribute("data-object");
      const ukSchoolThreeData = JSON.parse(uk_school_three)
      data.uk_school_three = ukSchoolThreeData
      data.uk_school_three["name"] = ukSchoolThreeData.value
      uk_school_details.push(ukSchoolThreeData)
    }
    if (getEduData >= 4 && document.querySelectorAll("#uk_school_four")[0].getAttribute("data-object")) {
      let uk_school_four = document.querySelectorAll("#uk_school_four")[0].getAttribute("data-object");
      const ukSchoolFourData = JSON.parse(uk_school_four)
      data.uk_school_four = ukSchoolFourData
      data.uk_school_four["name"] = ukSchoolFourData.value
      uk_school_details.push(ukSchoolFourData)
    }
    if (getEduData >= 5 && document.querySelectorAll("#uk_school_five")[0].getAttribute("data-object")) {
      let uk_school_five = document.querySelectorAll("#uk_school_five")[0].getAttribute("data-object");
      const ukSchoolFiveData = JSON.parse(uk_school_five)
      data.uk_school_five = ukSchoolFiveData
      data.uk_school_five["name"] = ukSchoolFiveData.value
      uk_school_details.push(ukSchoolFiveData)
    }
    (uk_school_details.length > 0) && (data["uk_school_details"] = uk_school_details)

    data = { ...commonLayoutData, ...data };
    let id = mainQualification.map((x) => {
      if (x.qualification_type == data?.main_qualification_details?.qualification_type) {
        data.main_qualification_details["id"] = x.id
        data.main_qualification_details["name"] = x.qualification_type
        return x.id
      }
    })

    // Removing non existing values

    id = id.filter(function (element) {
      return element !== undefined;
    });

    (data?.main_qualification_details?.id) && (data.main_qualification_details.id = id[0])
  }


  const submitData = data => {
    data = { ...commonLayoutData, ...data }
    SavingDataObject(data)
    setCommonLayoutData(data);

    if (getEduData >= 1 && data.gcse_school == "" || data.gcse_school == null) {
      setError('Please select GCSE school');
      return;
    }
    if (getEduData >= 2 && data.a_level_school == "" || data.a_level_school == null) {
      setError('Please select A-level school');
      return;
    }
    if (getEduData >= 3 && (data.uk_school_three == null || typeof(data.uk_school_three) == "string")) {
      setError('Please select school three');
      return;
    }
    if (getEduData >= 4 && (data.uk_school_four == null || typeof(data.uk_school_four) == "string")) {
      setError('Please select school four');
      return;
    }
    if (getEduData >= 5 && (data.uk_school_five == null || typeof(data.uk_school_five) == "string")) {
      setError('Please select school five');
      return;
    }
    if (data.postal_code_a_level == null || data.postal_code_a_level == undefined || typeof(data.postal_code_a_level) == "string") {
      setError('Please select A-level postal code');
      return;
    }
    if (data.postal_code_gcse.length > 0 && typeof(data.postal_code_gcse) == "string") {
      setError('Please select valid GCSE postal code');
      return;
    }
    if (data?.main_qualification_details?.qualification_type === "HE_COURSE") {
      const gradeOne = Number(data.main_qualification_details.he_course.grade["0"].split("D")[1])
      const gradeTwo = Number(data.main_qualification_details.he_course.grade["1"].split("M")[1])
      const gradeThree = Number(data.main_qualification_details.he_course.grade["2"].split("P")[1])
      const total = gradeOne + gradeTwo + gradeThree
      if (total > 45) {
        setError("HE course grade should be equal to 45");
        return;
      }
      if(data.main_qualification_details.he_course.grade["0"] == 'D0'){
        if(data.main_qualification_details.he_course.grade["1"] == 'M0'){
          if(data.main_qualification_details.he_course.grade["2"] == 'P0'){
            setError("Plese select the grade");
            return;
          }
        }
      }
    }

    setStep(5);
    setProgressValue("80");
  };

  return (
    <StyledWrapper className="apply-now">
      <Form onSubmit={handleSubmit(submitData)}>
        <Form.Label className="h_color">Eligibility</Form.Label>
        <p className="mb-1">
          <span>
            These questions will help us determine if you are eligible for upReach support. If you are unsure if you meet our
          </span>&nbsp;
          <a
            href="https://upreach.org.uk/students/#eligibility"
            target="_blank"
            className="checkbox-link"
          >
            eligibility criteria,
          </a>&nbsp;
          <span>please submit your application and our team can help. </span>
        </p>
        <h4 className="mb-0 mt-4">ACADEMIC HISTORY - GCSE STUDIES</h4>
        <Form.Label>
          GCSE Maths Grade <span className="required-text"> *</span>
        </Form.Label>
        <Form.Control
          name="gcse_maths"
          as="select"
          ref={register({ required: true })}
          onChange={e =>
            setCommonLayoutData({ ...commonLayoutData, ...getValues() })
          }
        >
          <option disabled selected value="">
            Select GCSE Maths grade
          </option>
          {gcseGrades.map((x, index) => (
            <option
              value={x.id}
              key={index}
              selected={x.id === commonLayoutData?.gcse_maths}
            >
              {x.name}
            </option>
          ))}
        </Form.Control>
        {errors.gcse_maths && (
          <p className="mb-0 text-danger font-weight-bold">
            Please check the GCSE Maths Grade
          </p>
        )}
        <Form.Label>
          GCSE English Grade <span className="required-text"> *</span>
        </Form.Label>
        <Form.Control
          as="select"
          name="gcse_english"
          ref={register({ required: true })}
          onChange={e =>
            setCommonLayoutData({ ...commonLayoutData, ...getValues() })
          }
        >
          <option value="" selected disabled>
            Select GCSE English grade
          </option>
          {gcseGrades.map((x, index) => (
            <option
              value={x.id}
              key={index}
              selected={x.id === commonLayoutData?.gcse_english}
            >
              {x.name}
            </option>
          ))}
        </Form.Control>
        {errors.gcse_english && (
          <p className="mb-0 text-danger font-weight-bold">
            Please check the GCSE english grade
          </p>
        )}
        <Form.Label>
          Which year did you complete your GCSE studies?{" "}
          <span className="required-text"> *</span>
        </Form.Label>
        <Form.Control
          ref={register({ required: true })}
          as="select"
          name="gcse_year"
          onChange={e =>
            setCommonLayoutData({ ...commonLayoutData, ...getValues() })
          }
        >
          <option value="" selected disabled>
            {" "}
            Select GCSE completion year{" "}
          </option>
          {yearsList.map((x, index) => (
            <option
              value={x.id}
              key={index}
              selected={commonLayoutData?.gcse_year == x}
            >
              {" "}
              {x}{" "}
            </option>
          ))}
        </Form.Control>
        {errors.gcse_year && (
          <p className="mb-0 text-danger font-weight-bold">
            Please select the year you completed your GCSE studies
          </p>
        )}
        <h4 className="mb-0 mt-4">ACADEMIC HISTORY - KEY STAGE 5 STUDIES</h4>
        <Form.Label>
          Which of the following Key Stage 5 qualifications do you hold (e.g.
          A-Level, BTEC, IB)? <span className="required-text"> *</span>
        </Form.Label>
        <Form.Control
          ref={register({ required: true })}
          as="select"
          name="main_qualification_details.qualification_type"
          onChange={e => {
            setQualificationContent(e.target.value);
            setCommonLayoutData({ ...commonLayoutData, ...getValues() });
          }}
        >
          <option value="" selecetd>
            Select KS5 qualification
          </option>
          {mainQualification.map(item => (
            <option
              id={item.id}
              value={item.qualification_type}
              key={item.name}
              selected={
                item.qualification_type ==
                commonLayoutData?.main_qualification_details?.qualification_type
              }
            >
              {item.name}
            </option>
          ))}
        </Form.Control>
        {errors.main_qualification_details?.qualification_type && (
          <p className="mb-0 text-danger font-weight-bold">
            Please select your main qualification
          </p>
        )}
        {/* A_LEVEL */}
        {qualificationContent == "A_LEVEL" && (
          <div>
            <Container className="p-0">
              <Row>
                <Col>
                  <Form.Label>
                    Subject title <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    ref={register({ required: true })}
                    id="a_level_title_one"
                    name="main_qualification_details.a_level.title_one_object"
                    onChange={e => {
                      e.target.value.length > 2? filterALevelOneSubject(e.target.value): setALevelOneSubjectSuggestions(null);
                      setCommonLayoutData({ ...commonLayoutData, ...getValues() });
                    }}
                    defaultValue={
                      commonLayoutData?.main_qualification_details?.a_level?.title_one_object?.value ? commonLayoutData?.main_qualification_details?.a_level?.title_one_object?.value : commonLayoutData?.main_qualification_details?.a_level?.title_one_object
                    }
                  />
                  {aLevelOneSubjectSuggestions != null &&
                    aLevelOneSubjectSuggestions.length > 0 && (
                      <div className="autocomplete_sugestions shadow p-3">
                        {aLevelOneSubjectSuggestions.map(x => (
                          <div>
                            <a
                              href=""
                              className="pb-1 text-reset text-decoration-none"
                              value={x.id}
                              onClick={e => {
                                e.preventDefault();
                                document.querySelector(
                                  "#a_level_title_one"
                                ).value = x.value;
                                document
                                  .querySelector("#a_level_title_one")
                                  .setAttribute(
                                    "data-object",
                                    JSON.stringify(x)
                                  );
                                setALevelOneSubjectSuggestions(null);
                                setValue("title_one_object", x);
                              }}
                            >
                              {x.value}
                            </a>
                          </div>
                        ))}
                      </div>
                    )}
                  {errors?.main_qualification_details?.a_level
                    ?.title_one_object && (
                      <p className="mb-0 text-danger font-weight-bold">title</p>
                    )}
                </Col>
                <Col>
                  <Form.Label>
                    Grade <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.a_level.grade_one"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                  >
                    <option value="" selected disabled></option>
                    {aLevelGrade.map((item, index) => (
                      <option
                        value={item.id}
                        key={item.name}
                        selected={
                          item.id ==
                          commonLayoutData?.main_qualification_details?.a_level
                            ?.grade_one
                        }
                      >
                        {item.name}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.a_level?.grade_one && (
                    <p className="mb-0 text-danger font-weight-bold">grade</p>
                  )}
                </Col>
                <Col>
                  <Form.Label>
                    Year awarded <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.a_level.year_one"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                  >
                    <option value="" selected disabled></option>
                    {yearsList.map((item, index) => (
                      <option
                        value={item}
                        key={item}
                        selected={
                          item ==
                          commonLayoutData?.main_qualification_details?.a_level
                            ?.year_one
                        }
                      >
                        {" "}
                        {item}{" "}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.a_level?.year_one && (
                    <p className="mb-0 text-danger font-weight-bold">year</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>
                    Subject title <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    id="a_level_title_two"
                    ref={register({ required: true })}
                    name="main_qualification_details.a_level.title_two_object"
                    defaultValue={
                      commonLayoutData?.main_qualification_details?.a_level?.title_two_object?.value ? commonLayoutData?.main_qualification_details?.a_level?.title_two_object?.value : commonLayoutData?.main_qualification_details?.a_level?.title_two_object
                    }
                    onChange={e => {
                      e.target.value.length > 2 ? filterALevelTwoSubject(e.target.value) : setALevelTwoSubjectSuggestions(null);
                      setCommonLayoutData({ ...commonLayoutData, ...getValues() });
                    }}
                  />
                  {aLevelTwoSubjectSuggestions != null &&
                    aLevelTwoSubjectSuggestions.length > 0 && (
                      <div className="autocomplete_sugestions shadow p-3">
                        {aLevelTwoSubjectSuggestions.map(x => (
                          <div>
                            <a
                              href=""
                              className="pb-1 text-reset text-decoration-none"
                              value={x}
                              onClick={e => {
                                e.preventDefault();
                                document.querySelector(
                                  "#a_level_title_two"
                                ).value = x.value;
                                document
                                  .querySelector("#a_level_title_two")
                                  .setAttribute(
                                    "data-object",
                                    JSON.stringify(x)
                                  );
                                setALevelTwoSubjectSuggestions(null);
                              }}
                            >
                              {x.value}
                            </a>
                          </div>
                        ))}
                      </div>
                    )}
                  {errors?.main_qualification_details?.a_level
                    ?.title_two_object && (
                      <p className="mb-0 text-danger font-weight-bold">title</p>
                    )}
                </Col>
                <Col>
                  <Form.Label>
                    Grade <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.a_level.grade_two"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                  >
                    <option value="" selected disabled></option>
                    {aLevelGrade.map((item, index) => (
                      <option
                        value={item.id}
                        key={item.name}
                        selected={
                          item.id ==
                          commonLayoutData?.main_qualification_details?.a_level
                            ?.grade_two
                        }
                      >
                        {item.name}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.a_level?.grade_two && (
                    <p className="mb-0 text-danger font-weight-bold">grade</p>
                  )}
                </Col>
                <Col>
                  <Form.Label>
                    Year awarded <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.a_level.year_two"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                  >
                    <option value="" selected disabled></option>
                    {yearsList.map((item, index) => (
                      <option
                        value={item}
                        key={item}
                        selected={
                          item ==
                          commonLayoutData?.main_qualification_details?.a_level
                            ?.year_two
                        }
                      >
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.a_level?.year_two && (
                    <p className="mb-0 text-danger font-weight-bold">year</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>
                    Subject title <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    id="a_level_title_three"
                    ref={register({ required: true })}
                    name="main_qualification_details.a_level.title_three_object"
                    defaultValue={
                      commonLayoutData?.main_qualification_details?.a_level?.title_three_object?.value ? commonLayoutData?.main_qualification_details?.a_level?.title_three_object?.value : commonLayoutData?.main_qualification_details?.a_level?.title_three_object
                    }
                    onChange={e => {
                      e.target.value.length > 2 ? filterALevelThreeSubject(e.target.value) : setALevelThreeSubjectSuggestions(null);
                      setCommonLayoutData({ ...commonLayoutData, ...getValues() });
                    }}
                  />
                  {aLevelThreeSubjectSuggestions != null &&
                    aLevelThreeSubjectSuggestions.length > 0 && (
                      <div className="autocomplete_sugestions shadow p-3">
                        {aLevelThreeSubjectSuggestions.map(x => (
                          <div>
                            <a
                              href=""
                              className="pb-1 text-reset text-decoration-none"
                              value={x.id}
                              onClick={e => {
                                e.preventDefault();
                                document.querySelector(
                                  "#a_level_title_three"
                                ).value = x.value;
                                document
                                  .querySelector("#a_level_title_three")
                                  .setAttribute(
                                    "data-object",
                                    JSON.stringify(x)
                                  );
                                setALevelThreeSubjectSuggestions(null);
                              }}
                            >
                              {x.value}
                            </a>
                          </div>
                        ))}
                      </div>
                    )}
                  {errors?.main_qualification_details?.a_level
                    ?.title_three_object && (
                      <p className="mb-0 text-danger font-weight-bold">title</p>
                    )}
                </Col>
                <Col>
                  <Form.Label>
                    Grade <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.a_level.grade_three"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                  >
                    <option value="" selected disabled></option>
                    {aLevelGrade.map((item, index) => (
                      <option
                        value={item.id}
                        key={item.name}
                        selected={
                          item.id ==
                          commonLayoutData?.main_qualification_details?.a_level
                            ?.grade_three
                        }
                      >
                        {item.name}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.a_level?.grade_three && (
                    <p className="mb-0 text-danger font-weight-bold">grade</p>
                  )}
                </Col>
                <Col>
                  <Form.Label>
                    Year awarded <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.a_level.year_three"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                  >
                    <option value="" selected disabled></option>
                    {yearsList.map((item, index) => (
                      <option
                        value={item}
                        key={item}
                        selected={
                          item ==
                          commonLayoutData?.main_qualification_details?.a_level
                            ?.year_three
                        }
                      >
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.a_level?.year_three && (
                    <p className="mb-0 text-danger font-weight-bold">year</p>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        )}
        {/* HE_COURSE */}
        {qualificationContent == "HE_COURSE" && (
          <div>
            <Container className="p-0">
              <Row>
                <Col>
                  <Form.Label>
                    Name of diploma <span className="required-text">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                    name="main_qualification_details.he_course.title"
                    defaultValue={
                      commonLayoutData !== null
                        ? commonLayoutData?.main_qualification_details
                          ?.he_course?.title
                        : ""
                    }
                  />
                  {errors?.main_qualification_details?.he_course?.title && (
                    <p className="mb-0 text-danger font-weight-bold">
                      Please enter diploma
                    </p>
                  )}
                </Col>
                <Col>
                  <Form.Label>
                    Grade <span className="required-text"> *</span>
                  </Form.Label>
                  <Row>
                    <Form.Control
                      ref={register({ required: true })}
                      as="select"
                      id="grade_one"
                      name="main_qualification_details.he_course.grade.0"
                      onChange={e => {
                        heDropdownChange(e.target.value, 1);
                        setCommonLayoutData({
                          ...commonLayoutData,
                          ...getValues(),
                        });
                      }}
                    >
                      {heGradesListOne.map((item, index) => (
                        <option
                          value={item.id}
                          key={item}
                          selected={
                            item ==
                            commonLayoutData?.main_qualification_details
                              ?.he_course?.grade?.[0]
                          }
                        >
                          {item}
                        </option>
                      ))}
                    </Form.Control>
                    {errors?.main_qualification_details?.he_course
                      ?.grade?.[0] && (
                        <p className="mb-0 text-danger font-weight-bold">Grade</p>
                      )}
                  </Row>
                  <Row>
                    <Form.Control
                      ref={register({ required: true })}
                      as="select"
                      name="main_qualification_details.he_course.grade.1"
                      onChange={e => {
                        heDropdownChange(e.target.value, 2);
                        setCommonLayoutData({
                          ...commonLayoutData,
                          ...getValues(),
                        });
                      }}
                    >
                      {heGradesListTwo.map((item, index) => (
                        <option
                          value={item.id}
                          key={item}
                          selected={
                            item ==
                            commonLayoutData?.main_qualification_details
                              ?.he_course?.grade?.[1]
                          }
                        >
                          {item}
                        </option>
                      ))}
                    </Form.Control>
                    {errors?.main_qualification_details?.he_course
                      ?.grade?.[1] && (
                        <p className="mb-0 text-danger font-weight-bold">Grade</p>
                      )}
                  </Row>
                  <Row>
                    <Form.Control
                      ref={register({ required: true })}
                      as="select"
                      name="main_qualification_details.he_course.grade.2"
                      onChange={e => {
                        heDropdownChange(e.target.value, 3);
                        setCommonLayoutData({
                          ...commonLayoutData,
                          ...getValues(),
                        });
                      }}
                    >
                      {heGradesListThree.map((item, index) => (
                        <option
                          value={item.id}
                          key={item}
                          selected={
                            item ==
                            commonLayoutData?.main_qualification_details
                              ?.he_course?.grade?.[2]
                          }
                        >
                          {item}
                        </option>
                      ))}
                    </Form.Control>
                    {errors?.main_qualification_details?.he_course
                      ?.grade?.[2] && (
                        <p className="mb-0 text-danger font-weight-bold">Grade</p>
                      )}
                  </Row>
                </Col>
                <Col>
                  <Form.Label>
                    Year awarded <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.he_course.year"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                  >
                    <option value="">Select Year</option>
                    {yearsList.map((item, index) => (
                      <option
                        value={item}
                        key={item}
                        selected={
                          item ==
                          commonLayoutData?.main_qualification_details
                            ?.he_course?.year
                        }
                      >
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.he_course?.year && (
                    <p className="mb-0 text-danger font-weight-bold">
                      Please select year
                    </p>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        )}
        {/* B_TECH_LEVEL_3 */}
        {qualificationContent == "B_TECH_LEVEL_3" && (
          <div>
            <Container className="p-0">
              <Row>
                <Col>
                  <Form.Label>
                    Subject title <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                    name="main_qualification_details.btech.title"
                    defaultValue={
                      commonLayoutData !== null
                        ? commonLayoutData?.main_qualification_details?.btech
                          ?.title
                        : ""
                    }
                  />
                  {errors?.main_qualification_details?.btech?.title && (
                    <p className="mb-0 text-danger font-weight-bold">
                      Please enter title
                    </p>
                  )}
                </Col>
                <Col>
                  <Form.Label>
                    Grade <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.btech.grade"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                  >
                    <option value="" selected>
                      Please Select Grade
                    </option>
                    {btechMixGradesList.map((item, index) => (
                      <option
                        value={Object.values(item)}
                        key={index}
                        selected={
                          Object.values(item) ==
                          commonLayoutData?.main_qualification_details?.btech
                            ?.grade
                        }
                      >
                        {Object.values(item)}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.btech?.grade && (
                    <p className="mb-0 text-danger font-weight-bold">Grade</p>
                  )}
                </Col>
                <Col>
                  <Form.Label>
                    Year awarded <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.btech.year"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                  >
                    <option value="" selected>
                      Select year
                    </option>
                    {yearsList.map((item, index) => (
                      <option
                        value={item}
                        key={item}
                        selected={
                          item ==
                          commonLayoutData?.main_qualification_details?.btech
                            ?.year
                        }
                      >
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.btech?.year && (
                    <p className="mb-0 text-danger font-weight-bold">
                      Please select year
                    </p>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        )}
        {/* FOUNDATION_YEAR */}
        {qualificationContent == "FOUNDATION_YEAR" && (
          <div>
            <Container className="p-0">
              <Row>
                <Col>
                  <Form.Label>
                    Name of qualification
                    <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    ref={register({ required: true })}
                    name="main_qualification_details.foundation_year.nameofqualification"
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                    defaultValue={
                      commonLayoutData !== null
                        ? commonLayoutData?.main_qualification_details
                          ?.foundation_year?.nameofqualification
                        : ""
                    }
                  />
                  {errors?.main_qualification_details?.foundation_year
                    ?.nameofqualification && (
                      <p className="mb-0 text-danger font-weight-bold">
                        Please enter title
                      </p>
                    )}
                </Col>
                <Col>
                  <Form.Label>
                    Grade awarded <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                    name="main_qualification_details.foundation_year.grade"
                    defaultValue={
                      commonLayoutData !== null
                        ? commonLayoutData?.main_qualification_details
                          ?.foundation_year?.grade
                        : ""
                    }
                  />
                  {errors?.main_qualification_details?.foundation_year
                    ?.grade && (
                      <p className="mb-0 text-danger font-weight-bold">
                        Please enter grade
                      </p>
                    )}
                </Col>
                <Col>
                  <Form.Label>
                    Year awarded <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.foundation_year.year"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                  >
                    <option value=""></option>
                    {yearsList.map((item, index) => (
                      <option
                        value={item}
                        key={item}
                        selected={
                          item ==
                          commonLayoutData?.main_qualification_details
                            ?.foundation_year?.year
                        }
                      >
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.foundation_year
                    ?.year && (
                      <p className="mb-0 text-danger font-weight-bold">
                        Please enter year
                      </p>
                    )}
                </Col>
              </Row>
            </Container>
          </div>
        )}
        {/* IB */}
        {qualificationContent == "IB" && (
          <div>
            <Container className="p-0">
              <Row>
                <Col>
                  <Form.Label>
                    Total IB Points <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.ib.0.points"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                  >
                    <option value=""></option>
                    {ibGradesList.map((item, index) => (
                      <option
                        value={item}
                        key={item}
                        selected={
                          item ==
                          commonLayoutData?.main_qualification_details?.ib?.[0]
                            ?.points
                        }
                      >
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.ib?.[0]?.points && (
                    <p className="mb-0 text-danger font-weight-bold">
                      Please select points
                    </p>
                  )}
                </Col>
                <Col>
                  <Form.Label>
                    Year awarded <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.ib.0.year"
                    ref={register({ required: true })}
                    onChange={e =>
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      })
                    }
                  >
                    <option value=""></option>
                    {yearsList.map((item, index) => (
                      <option
                        value={item}
                        key={item}
                        selected={
                          item ==
                          commonLayoutData?.main_qualification_details?.ib?.[0]
                            ?.year
                        }
                      >
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.ib?.[0]?.year && (
                    <p className="mb-0 text-danger font-weight-bold">
                      Please select year
                    </p>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        )}
        {/* MIX_OF_THE_ABOVE_QUALIFICATION */}
        {qualificationContent == "MIX_OF_THE_ABOVE_QUALIFICATION" && (
          <div>
            <Container className="p-0">
              <Row>
                <Col>
                  <Form.Label>
                    Qualification <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.mix_qualifications.0.qualification"
                    ref={register({ required: true })}
                    onChange={e => {
                      setMixQualificationOne(e.target.value);
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      });
                    }}
                  >
                    <option value=""></option>
                    {qualifications.map((item, index) => (
                      <option
                        value={Object.keys(item)}
                        key={index}
                        selected={
                          Object.keys(item) ==
                          commonLayoutData?.main_qualification_details
                            ?.mix_qualifications?.[0]?.qualification
                        }
                      >
                        {Object.values(item)}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.mix_qualifications?.[0]
                    ?.qualification && (
                      <p className="mb-0 text-danger font-weight-bold">
                        Please select qualification
                      </p>
                    )}
                </Col>
                {mixQualitficationOne !== "IB" &&
                  mixQualitficationOne !== "ADDITIONAL_QUALIFICATION" && (
                    <Col>
                      <Form.Label>
                        Subject <span className="required-text"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        ref={register({ required: true })}
                        onChange={e =>
                          setCommonLayoutData({
                            ...commonLayoutData,
                            ...getValues(),
                          })
                        }
                        name="main_qualification_details.mix_qualifications.0.subject"
                        defaultValue={
                          commonLayoutData !== null
                            ? commonLayoutData?.main_qualification_details
                              ?.mix_qualifications?.[0]?.subject
                            : ""
                        }
                      />
                      {errors?.main_qualification_details
                        ?.mix_qualifications?.[0]?.subject && (
                          <p className="mb-0 text-danger font-weight-bold">
                            Please enter subject
                          </p>
                        )}
                    </Col>
                  )}
                <Col>
                  {mixQualitficationOne !== "B_TECH_LEVEL_3" &&
                    mixQualitficationOne !== "IB" &&
                    mixQualitficationOne !== "ADDITIONAL_QUALIFICATION" && (
                      <div>
                        <Form.Label>
                          Grade <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="main_qualification_details.mix_qualifications.0.grade"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                        >
                          <option value=""></option>
                          {aLevelGrade.map((item, index) => (
                            <option
                              value={item.id}
                              key={item.name}
                              selected={
                                item.id ==
                                commonLayoutData?.main_qualification_details
                                  ?.mix_qualifications?.[0]?.grade
                              }
                            >
                              {item.name}
                            </option>
                          ))}
                        </Form.Control>
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[0]?.grade && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please select grade
                            </p>
                          )}
                      </div>
                    )}
                  {mixQualitficationOne == "B_TECH_LEVEL_3" && (
                    <div>
                      <Form.Label>
                        Grade <span className="required-text"> *</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="main_qualification_details.mix_qualifications.0.grade"
                        ref={register({ required: true })}
                        onChange={e =>
                          setCommonLayoutData({
                            ...commonLayoutData,
                            ...getValues(),
                          })
                        }
                      >
                        <option value=""></option>
                        {btechMixGradesList.map((item, index) => (
                          <option
                            value={Object.values(item)}
                            key={index}
                            selected={
                              Object.values(item) ==
                              commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[0]?.grade
                            }
                          >
                            {Object.values(item)}
                          </option>
                        ))}
                      </Form.Control>
                      {errors?.main_qualification_details
                        ?.mix_qualifications?.[0]?.grade && (
                          <p className="mb-0 text-danger font-weight-bold">
                            Please select grade
                          </p>
                        )}
                    </div>
                  )}
                  {mixQualitficationOne == "IB" &&
                    mixQualitficationOne !== "ADDITIONAL_QUALIFICATION" && (
                      <div>
                        <Form.Label>
                          Total IB Points
                          <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          {...register("points")}
                          as="select"
                          name="main_qualification_details.mix_qualifications.0.points"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                        >
                          <option value=""></option>
                          {ibGradesList.map((item, index) => (
                            <option
                              value={item}
                              key={item}
                              selected={
                                item ==
                                commonLayoutData?.main_qualification_details
                                  ?.mix_qualifications?.[0]?.points
                              }
                            >
                              {item}
                            </option>
                          ))}
                        </Form.Control>
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[0]?.points && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please select points
                            </p>
                          )}
                      </div>
                    )}
                </Col>
                <Col>
                  {mixQualitficationOne !== "ADDITIONAL_QUALIFICATION" && (
                    <div>
                      <Form.Label>
                        Year awarded <span className="required-text"> *</span>
                      </Form.Label>
                      <Form.Control
                        {...register("year")}
                        as="select"
                        name="main_qualification_details.mix_qualifications.0.year"
                        ref={register({ required: true })}
                        onChange={e =>
                          setCommonLayoutData({
                            ...commonLayoutData,
                            ...getValues(),
                          })
                        }
                      >
                        <option value=""></option>
                        {yearsList.map((item, index) => (
                          <option
                            value={item}
                            key={item}
                            selected={
                              item ==
                              commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[0]?.year
                            }
                          >
                            {item}
                          </option>
                        ))}
                      </Form.Control>
                      {errors?.main_qualification_details
                        ?.mix_qualifications?.[0]?.year && (
                          <p className="mb-0 text-danger font-weight-bold">
                            Please select year
                          </p>
                        )}
                    </div>
                  )}
                </Col>
                {mixQualitficationOne == "ADDITIONAL_QUALIFICATION" && (
                  <Container>
                    <Row>
                      <Col>
                        <Form.Label>
                          Qualification Type
                          <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                          name="main_qualification_details.mix_qualifications.0.additional_qualification.qualification"
                          defaultValue={
                            commonLayoutData !== null
                              ? commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[0]
                                ?.additional_qualification?.qualification
                              : ""
                          }
                        />
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[0]?.additional_qualification
                          ?.qualification && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please enter qualification
                            </p>
                          )}
                      </Col>
                      <Col>
                        <Form.Label>
                          Subject title
                          <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                          name="main_qualification_details.mix_qualifications.0.additional_qualification.title"
                          defaultValue={
                            commonLayoutData !== null
                              ? commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[0]
                                ?.additional_qualification?.title
                              : ""
                          }
                        />
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[0]?.additional_qualification
                          ?.title && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please enter title
                            </p>
                          )}
                      </Col>
                      <Col>
                        <Form.Label>
                          Grade <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                          name="main_qualification_details.mix_qualifications.0.additional_qualification.Grade"
                          defaultValue={
                            commonLayoutData !== null
                              ? commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[0]
                                ?.additional_qualification?.Grade
                              : ""
                          }
                        />
                        {errors?.main_qualification_details?.qualification
                          ?.qualification_one?.additional_qualification
                          ?.Grade && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please enter Grade
                            </p>
                          )}
                      </Col>
                      <Col>
                        <Form.Label>
                          Year awarded <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="main_qualification_details.mix_qualifications.0.additional_qualification.year"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                        >
                          <option value=""></option>
                          {yearsList.map((item, index) => (
                            <option
                              value={item}
                              key={item}
                              selected={
                                item ==
                                commonLayoutData?.main_qualification_details
                                  ?.mix_qualifications?.[0]
                                  ?.additional_qualification?.year
                              }
                            >
                              {item}
                            </option>
                          ))}
                        </Form.Control>
                        {errors?.main_qualification_details?.qualification
                          ?.qualification_one?.additional_qualification
                          ?.year && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please select year
                            </p>
                          )}
                      </Col>
                    </Row>
                  </Container>
                )}
              </Row>
            </Container>
            <Container className="p-0">
              <Row>
                <Col>
                  <Form.Label>
                    Qualification <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.mix_qualifications.1.qualification"
                    ref={register({ required: true })}
                    onChange={e => {
                      setMixQualificationTwo(e.target.value);
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      });
                    }}
                  >
                    <option value=""></option>
                    {qualifications.map((item, index) => (
                      <option
                        value={Object.keys(item)}
                        key={index}
                        selected={
                          Object.keys(item) ==
                          commonLayoutData?.main_qualification_details
                            ?.mix_qualifications?.[1]?.qualification
                        }
                      >
                        {Object.values(item)}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.mix_qualifications?.[1]
                    ?.qualification && (
                      <p className="mb-0 text-danger font-weight-bold">
                        Please select qualification
                      </p>
                    )}
                </Col>
                {mixQualitficationTwo !== "IB" &&
                  mixQualitficationTwo !== "ADDITIONAL_QUALIFICATION" && (
                    <Col>
                      <Form.Label>
                        Subject <span className="required-text"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        ref={register({ required: true })}
                        onChange={e =>
                          setCommonLayoutData({
                            ...commonLayoutData,
                            ...getValues(),
                          })
                        }
                        name="main_qualification_details.mix_qualifications.1.subject"
                        defaultValue={
                          commonLayoutData !== null
                            ? commonLayoutData?.main_qualification_details
                              ?.mix_qualifications?.[1]?.subject
                            : ""
                        }
                      />
                      {errors?.main_qualification_details
                        ?.mix_qualifications?.[1]?.subject && (
                          <p className="mb-0 text-danger font-weight-bold">
                            Please enter subject
                          </p>
                        )}
                    </Col>
                  )}
                <Col>
                  {mixQualitficationTwo !== "B_TECH_LEVEL_3" &&
                    mixQualitficationTwo !== "IB" &&
                    mixQualitficationTwo !== "ADDITIONAL_QUALIFICATION" && (
                      <div>
                        <Form.Label>
                          Grade <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="main_qualification_details.mix_qualifications.1.grade"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                        >
                          <option value=""></option>
                          {aLevelGrade.map((item, index) => (
                            <option
                              value={item.id}
                              key={item.name}
                              selected={
                                item.id ==
                                commonLayoutData?.main_qualification_details
                                  ?.mix_qualifications?.[1]?.grade
                              }
                            >
                              {item.name}
                            </option>
                          ))}
                        </Form.Control>
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[1]?.grade && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please select grade
                            </p>
                          )}
                      </div>
                    )}
                  {mixQualitficationTwo == "B_TECH_LEVEL_3" && (
                    <div>
                      <Form.Label>
                        Grade <span className="required-text"> *</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="main_qualification_details.mix_qualifications.1.grade"
                        ref={register({ required: true })}
                        onChange={e =>
                          setCommonLayoutData({
                            ...commonLayoutData,
                            ...getValues(),
                          })
                        }
                      >
                        <option value=""></option>
                        {btechMixGradesList.map((item, index) => (
                          <option
                            value={Object.values(item)}
                            key={index}
                            selected={
                              Object.values(item) ==
                              commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[1]?.grade
                            }
                          >
                            {Object.values(item)}
                          </option>
                        ))}
                      </Form.Control>
                      {errors?.main_qualification_details
                        ?.mix_qualifications?.[1]?.grade && (
                          <p className="mb-0 text-danger font-weight-bold">
                            Please select grade
                          </p>
                        )}
                    </div>
                  )}
                  {mixQualitficationTwo == "IB" &&
                    mixQualitficationTwo !== "ADDITIONAL_QUALIFICATION" && (
                      <div>
                        <Form.Label>
                          Total IB Points
                          <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          {...register("points")}
                          as="select"
                          name="main_qualification_details.mix_qualifications.1.points"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                        >
                          <option value=""></option>
                          {ibGradesList.map((item, index) => (
                            <option
                              value={item}
                              key={item}
                              selected={
                                item ==
                                commonLayoutData?.main_qualification_details
                                  ?.mix_qualifications?.[1]?.points
                              }
                            >
                              {item}
                            </option>
                          ))}
                        </Form.Control>
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[1]?.points && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please select points
                            </p>
                          )}
                      </div>
                    )}
                </Col>
                <Col>
                  {mixQualitficationTwo !== "ADDITIONAL_QUALIFICATION" && (
                    <div>
                      <Form.Label>
                        Year awarded <span className="required-text"> *</span>
                      </Form.Label>
                      <Form.Control
                        {...register("year")}
                        as="select"
                        name="main_qualification_details.mix_qualifications.1.year"
                        ref={register({ required: true })}
                        onChange={e =>
                          setCommonLayoutData({
                            ...commonLayoutData,
                            ...getValues(),
                          })
                        }
                      >
                        <option value=""></option>
                        {yearsList.map((item, index) => (
                          <option
                            value={item}
                            key={item}
                            selected={
                              item ==
                              commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[1]?.year
                            }
                          >
                            {item}
                          </option>
                        ))}
                      </Form.Control>
                      {errors?.main_qualification_details
                        ?.mix_qualifications?.[1]?.year && (
                          <p className="mb-0 text-danger font-weight-bold">
                            Please select year
                          </p>
                        )}
                    </div>
                  )}
                </Col>
                {mixQualitficationTwo == "ADDITIONAL_QUALIFICATION" && (
                  <Container>
                    <Row>
                      <Col>
                        <Form.Label>
                          Qualification Type
                          <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                          name="main_qualification_details.mix_qualifications.1.additional_qualification.qualification"
                          defaultValue={
                            commonLayoutData !== null
                              ? commonLayoutData.main_qualification_details
                                ?.mix_qualifications?.[1]
                                ?.additional_qualification?.qualification
                              : ""
                          }
                        />
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[1]?.additional_qualification
                          ?.qualification && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please enter qualification
                            </p>
                          )}
                      </Col>
                      <Col>
                        <Form.Label>
                          Subject title
                          <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                          name="main_qualification_details.mix_qualifications.1.additional_qualification.title"
                          defaultValue={
                            commonLayoutData !== null
                              ? commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[1]
                                ?.additional_qualification?.title
                              : ""
                          }
                        />
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[1]?.additional_qualification
                          ?.title && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please enter title
                            </p>
                          )}
                      </Col>
                      <Col>
                        <Form.Label>
                          Grade <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                          name="main_qualification_details.mix_qualifications.1.additional_qualification.Grade"
                          defaultValue={
                            commonLayoutData !== null
                              ? commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[1]
                                ?.additional_qualification?.Grade
                              : ""
                          }
                        />
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[1]?.additional_qualification
                          ?.Grade && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please enter Grade
                            </p>
                          )}
                      </Col>
                      <Col>
                        <Form.Label>
                          Year awarded <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="main_qualification_details.mix_qualifications.1.additional_qualification.year"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                        >
                          <option value=""></option>
                          {yearsList.map((item, index) => (
                            <option
                              value={item}
                              key={item}
                              selected={
                                item ==
                                commonLayoutData?.main_qualification_details
                                  ?.mix_qualifications?.[1]
                                  ?.additional_qualification?.year
                              }
                            >
                              {item}
                            </option>
                          ))}
                        </Form.Control>
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[1]?.additional_qualification
                          ?.year && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please select year
                            </p>
                          )}
                      </Col>
                    </Row>
                  </Container>
                )}
              </Row>
            </Container>
            <Container className="p-0">
              <Row>
                <Col>
                  <Form.Label>
                    Qualification <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.mix_qualifications.2.qualification"
                    ref={register({ required: true })}
                    onChange={e => {
                      setMixQualificationThree(e.target.value);
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      });
                    }}
                  >
                    <option value=""></option>
                    {qualifications.map((item, index) => (
                      <option
                        value={Object.keys(item)}
                        key={index}
                        selected={
                          Object.keys(item) ==
                          commonLayoutData?.main_qualification_details
                            ?.mix_qualifications?.[2]?.qualification
                        }
                      >
                        {Object.values(item)}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.mix_qualifications?.[2]
                    ?.qualification && (
                      <p className="mb-0 text-danger font-weight-bold">
                        Please select qualification
                      </p>
                    )}
                </Col>
                {mixQualitficationThree !== "IB" &&
                  mixQualitficationThree !== "ADDITIONAL_QUALIFICATION" && (
                    <Col>
                      <Form.Label>
                        Subject <span className="required-text"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        ref={register({ required: true })}
                        onChange={e =>
                          setCommonLayoutData({
                            ...commonLayoutData,
                            ...getValues(),
                          })
                        }
                        name="main_qualification_details.mix_qualifications.2.subject"
                        defaultValue={
                          commonLayoutData !== null
                            ? commonLayoutData?.main_qualification_details
                              ?.mix_qualifications?.[2]?.subject
                            : ""
                        }
                      />
                      {errors?.main_qualification_details
                        ?.mix_qualifications?.[2]?.subject && (
                          <p className="mb-0 text-danger font-weight-bold">
                            Please enter subject
                          </p>
                        )}
                    </Col>
                  )}
                <Col>
                  {mixQualitficationThree !== "B_TECH_LEVEL_3" &&
                    mixQualitficationThree !== "IB" &&
                    mixQualitficationThree !== "ADDITIONAL_QUALIFICATION" && (
                      <div>
                        <Form.Label>
                          Grade <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="main_qualification_details.mix_qualifications.2.grade"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                        >
                          <option value=""></option>
                          {aLevelGrade.map((item, index) => (
                            <option
                              value={item.id}
                              key={item.name}
                              selected={
                                item.id ==
                                commonLayoutData?.main_qualification_details
                                  ?.mix_qualifications?.[2]?.grade
                              }
                            >
                              {item.name}
                            </option>
                          ))}
                        </Form.Control>
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[2]?.grade && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please select grade
                            </p>
                          )}
                      </div>
                    )}
                  {mixQualitficationThree == "B_TECH_LEVEL_3" && (
                    <div>
                      <Form.Label>
                        Grade <span className="required-text"> *</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="main_qualification_details.mix_qualifications.2.grade"
                        ref={register({ required: true })}
                        onChange={e =>
                          setCommonLayoutData({
                            ...commonLayoutData,
                            ...getValues(),
                          })
                        }
                      >
                        <option value=""></option>
                        {btechMixGradesList.map((item, index) => (
                          <option
                            value={Object.values(item)}
                            key={index}
                            selected={
                              Object.values(item) ==
                              commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[2]?.grade
                            }
                          >
                            {Object.values(item)}
                          </option>
                        ))}
                      </Form.Control>
                      {errors?.main_qualification_details
                        ?.mix_qualifications?.[2]?.grade && (
                          <p className="mb-0 text-danger font-weight-bold">
                            Please select grade
                          </p>
                        )}
                    </div>
                  )}
                  {mixQualitficationThree == "IB" &&
                    mixQualitficationThree !== "ADDITIONAL_QUALIFICATION" && (
                      <div>
                        <Form.Label>
                          Total IB Points
                          <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          {...register("points")}
                          as="select"
                          name="main_qualification_details.mix_qualifications.2.points"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                        >
                          <option value=""></option>
                          {ibGradesList.map((item, index) => (
                            <option
                              value={item}
                              key={item}
                              selected={
                                item ==
                                commonLayoutData?.main_qualification_details
                                  ?.mix_qualifications?.[2]?.points
                              }
                            >
                              {item}
                            </option>
                          ))}
                        </Form.Control>
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[2]?.points && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please select points
                            </p>
                          )}
                      </div>
                    )}
                </Col>
                <Col>
                  {mixQualitficationThree !== "ADDITIONAL_QUALIFICATION" && (
                    <div>
                      <Form.Label>
                        Year awarded <span className="required-text"> *</span>
                      </Form.Label>
                      <Form.Control
                        {...register("year")}
                        as="select"
                        name="main_qualification_details.mix_qualifications.2.year"
                        ref={register({ required: true })}
                        onChange={e =>
                          setCommonLayoutData({
                            ...commonLayoutData,
                            ...getValues(),
                          })
                        }
                      >
                        <option value=""></option>
                        {yearsList.map((item, index) => (
                          <option
                            value={item}
                            key={item}
                            selected={
                              item ==
                              commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[2]?.year
                            }
                          >
                            {item}
                          </option>
                        ))}
                      </Form.Control>
                      {errors?.main_qualification_details
                        ?.mix_qualifications?.[2]?.year && (
                          <p className="mb-0 text-danger font-weight-bold">
                            Please select year
                          </p>
                        )}
                    </div>
                  )}
                </Col>
                {mixQualitficationThree == "ADDITIONAL_QUALIFICATION" && (
                  <Container>
                    <Row>
                      <Col>
                        <Form.Label>
                          Qualification Type
                          <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                          name="main_qualification_details.mix_qualifications.2.additional_qualification.qualification"
                          defaultValue={
                            commonLayoutData !== null
                              ? commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[2]
                                ?.additional_qualification?.qualification
                              : ""
                          }
                        />
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[2]?.additional_qualification
                          ?.qualification && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please enter qualification
                            </p>
                          )}
                      </Col>
                      <Col>
                        <Form.Label>
                          Subject title
                          <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                          name="main_qualification_details.mix_qualifications.2.additional_qualification.title"
                          defaultValue={
                            commonLayoutData !== null
                              ? commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[2]
                                ?.additional_qualification?.title
                              : ""
                          }
                        />
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[2]?.additional_qualification
                          ?.title && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please enter title
                            </p>
                          )}
                      </Col>
                      <Col>
                        <Form.Label>
                          Grade <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                          name="main_qualification_details.mix_qualifications.2.additional_qualification.Grade"
                          defaultValue={
                            commonLayoutData !== null
                              ? commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[2]
                                ?.additional_qualification?.Grade
                              : ""
                          }
                        />
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[2]?.additional_qualification
                          ?.Grade && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please enter Grade
                            </p>
                          )}
                      </Col>
                      <Col>
                        <Form.Label>
                          Year awarded <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="main_qualification_details.mix_qualifications.2.additional_qualification.year"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                        >
                          <option value=""></option>
                          {yearsList.map((item, index) => (
                            <option
                              value={item}
                              key={item}
                              selected={
                                item ==
                                commonLayoutData?.main_qualification_details
                                  ?.mix_qualifications?.[2]
                                  ?.additional_qualification?.year
                              }
                            >
                              {item}
                            </option>
                          ))}
                        </Form.Control>
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[2]?.additional_qualification
                          ?.year && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please select year
                            </p>
                          )}
                      </Col>
                    </Row>
                  </Container>
                )}
              </Row>
            </Container>
            <Container className="p-0">
              <Row>
                <Col>
                  <Form.Label>
                    Qualification <span className="required-text"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="main_qualification_details.mix_qualifications.3.qualification"
                    ref={register({ required: true })}
                    onChange={e => {
                      setMixQualificationFour(e.target.value);
                      setCommonLayoutData({
                        ...commonLayoutData,
                        ...getValues(),
                      });
                    }}
                  >
                    <option value=""></option>
                    {qualifications.map((item, index) => (
                      <option
                        value={Object.keys(item)}
                        key={index}
                        selected={
                          Object.keys(item) ==
                          commonLayoutData?.main_qualification_details
                            ?.mix_qualifications?.[3]?.qualification
                        }
                      >
                        {Object.values(item)}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.main_qualification_details?.mix_qualifications?.[3]
                    ?.qualification && (
                      <p className="mb-0 text-danger font-weight-bold">
                        Please select qualification
                      </p>
                    )}
                </Col>
                {mixQualitficationFour !== "IB" &&
                  mixQualitficationFour !== "ADDITIONAL_QUALIFICATION" && (
                    <Col>
                      <Form.Label>
                        Subject <span className="required-text"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        ref={register({ required: true })}
                        onChange={e =>
                          setCommonLayoutData({
                            ...commonLayoutData,
                            ...getValues(),
                          })
                        }
                        name="main_qualification_details.mix_qualifications.3.subject"
                        defaultValue={
                          commonLayoutData?.main_qualification_details
                            ?.mix_qualifications?.[3]?.subject
                        }
                      />
                      {errors?.main_qualification_details
                        ?.mix_qualifications?.[3]?.subject && (
                          <p className="mb-0 text-danger font-weight-bold">
                            Please enter subject
                          </p>
                        )}
                    </Col>
                  )}
                <Col>
                  {mixQualitficationFour !== "B_TECH_LEVEL_3" &&
                    mixQualitficationFour !== "IB" &&
                    mixQualitficationFour !== "ADDITIONAL_QUALIFICATION" && (
                      <div>
                        <Form.Label>
                          Grade <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="main_qualification_details.mix_qualifications.3.grade"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                        >
                          <option value=""></option>
                          {aLevelGrade.map((item, index) => (
                            <option
                              value={item.id}
                              key={item.name}
                              selected={
                                item.id ==
                                commonLayoutData?.main_qualification_details
                                  ?.mix_qualifications?.[3]?.grade
                              }
                            >
                              {item.name}
                            </option>
                          ))}
                        </Form.Control>
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[3]?.grade && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please select grade
                            </p>
                          )}
                      </div>
                    )}
                  {mixQualitficationFour == "B_TECH_LEVEL_3" && (
                    <div>
                      <Form.Label>
                        Grade <span className="required-text"> *</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="main_qualification_details.mix_qualifications.3.grade"
                        ref={register({ required: true })}
                        onChange={e =>
                          setCommonLayoutData({
                            ...commonLayoutData,
                            ...getValues(),
                          })
                        }
                      >
                        <option value=""></option>
                        {btechMixGradesList.map((item, index) => (
                          <option
                            value={Object.values(item)}
                            key={index}
                            selected={
                              Object.values(item) ==
                              commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[3]?.grade
                            }
                          >
                            {Object.values(item)}
                          </option>
                        ))}
                      </Form.Control>
                      {errors?.main_qualification_details
                        ?.mix_qualifications?.[3]?.grade && (
                          <p className="mb-0 text-danger font-weight-bold">
                            Please select grade
                          </p>
                        )}
                    </div>
                  )}
                  {mixQualitficationFour == "IB" &&
                    mixQualitficationFour !== "ADDITIONAL_QUALIFICATION" && (
                      <div>
                        <Form.Label>
                          Total IB Points
                          <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          {...register("points")}
                          as="select"
                          name="main_qualification_details.mix_qualifications.3.points"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                        >
                          <option value=""></option>
                          {ibGradesList.map((item, index) => (
                            <option
                              value={item}
                              key={item}
                              selected={
                                item ==
                                commonLayoutData?.main_qualification_details
                                  ?.mix_qualifications?.[3]?.points
                              }
                            >
                              {item}
                            </option>
                          ))}
                        </Form.Control>
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[3]?.points && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please select points
                            </p>
                          )}
                      </div>
                    )}
                </Col>
                <Col>
                  {mixQualitficationFour !== "ADDITIONAL_QUALIFICATION" && (
                    <div>
                      <Form.Label>
                        Year awarded <span className="required-text"> *</span>
                      </Form.Label>
                      <Form.Control
                        {...register("year")}
                        as="select"
                        name="main_qualification_details.mix_qualifications.3.year"
                        ref={register({ required: true })}
                        onChange={e =>
                          setCommonLayoutData({
                            ...commonLayoutData,
                            ...getValues(),
                          })
                        }
                      >
                        <option value=""></option>
                        {yearsList.map((item, index) => (
                          <option
                            value={item}
                            key={item}
                            selected={
                              item ==
                              commonLayoutData?.main_qualification_details
                                ?.mix_qualifications?.[3]?.year
                            }
                          >
                            {item}
                          </option>
                        ))}
                      </Form.Control>
                      {errors?.main_qualification_details
                        ?.mix_qualifications?.[3]?.year && (
                          <p className="mb-0 text-danger font-weight-bold">
                            Please select year
                          </p>
                        )}
                    </div>
                  )}
                </Col>
                {mixQualitficationFour == "ADDITIONAL_QUALIFICATION" && (
                  <Container>
                    <Row>
                      <Col>
                        <Form.Label>
                          Qualification Type
                          <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                          name="main_qualification_details.mix_qualifications.3.additional_qualification.qualification"
                          defaultValue={
                            commonLayoutData?.main_qualification_details
                              ?.mix_qualifications?.[3]
                              ?.additional_qualification?.qualification
                          }
                        />
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[3]?.additional_qualification
                          ?.qualification && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please enter qualification
                            </p>
                          )}
                      </Col>
                      <Col>
                        <Form.Label>
                          Subject title
                          <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                          name="main_qualification_details.mix_qualifications.3.additional_qualification.title"
                          defaultValue={
                            commonLayoutData?.main_qualification_details
                              ?.mix_qualifications?.[3]
                              ?.additional_qualification?.title
                          }
                        />
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[3]?.additional_qualification
                          ?.title && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please enter title
                            </p>
                          )}
                      </Col>
                      <Col>
                        <Form.Label>
                          Grade <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                          name="main_qualification_details.mix_qualifications.3.additional_qualification.Grade"
                          defaultValue={
                            commonLayoutData?.main_qualification_details
                              ?.mix_qualifications?.[3]
                              ?.additional_qualification?.Grade
                          }
                        />
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[3]?.additional_qualification
                          ?.Grade && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please enter Grade
                            </p>
                          )}
                      </Col>
                      <Col>
                        <Form.Label>
                          Year awarded <span className="required-text"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="main_qualification_details.mix_qualifications.3.additional_qualification.year"
                          ref={register({ required: true })}
                          onChange={e =>
                            setCommonLayoutData({
                              ...commonLayoutData,
                              ...getValues(),
                            })
                          }
                        >
                          <option value=""></option>
                          {yearsList.map((item, index) => (
                            <option
                              value={item}
                              key={item}
                              selected={
                                item ==
                                commonLayoutData?.main_qualification_details
                                  ?.mix_qualifications?.[3]
                                  ?.additional_qualification?.year
                              }
                            >
                              {item}
                            </option>
                          ))}
                        </Form.Control>
                        {errors?.main_qualification_details
                          ?.mix_qualifications?.[3]?.additional_qualification
                          ?.year && (
                            <p className="mb-0 text-danger font-weight-bold">
                              Please select year
                            </p>
                          )}
                      </Col>
                    </Row>
                  </Container>
                )}
              </Row>
            </Container>
          </div>
        )}
        {/* OTHER */}
        {qualificationContent == "OTHER" && (
          <div>
            <Form.Label>
              Please state which qualifications you have completed
            </Form.Label>
            <Form.Control
              as="textarea"
              ref={register({ required: true })}
              placeholder="Max. 500 characters"
              name="main_qualification_details.other"
              rows={3}
              onChange={e =>
                setCommonLayoutData({ ...commonLayoutData, ...getValues() })
              }
              defaultValue={commonLayoutData?.main_qualification_details?.other}
            />
            {errors?.main_qualification_details?.other && (
              <p className="mb-0 text-danger font-weight-bold">Required</p>
            )}

            <Form.Label>What grade did you achieve?</Form.Label>
            <Form.Control
              as="textarea"
              ref={register({ required: true })}
              placeholder="Max. 500 characters"
              name="main_qualification_details.other_qualification_grade"
              rows={3}
              onChange={e =>
                setCommonLayoutData({ ...commonLayoutData, ...getValues() })
              }
              defaultValue={
                commonLayoutData?.main_qualification_details
                  ?.other_qualification_grade
              }
            />
            {errors?.main_qualification_details?.other_qualification_grade && (
              <p className="mb-0 text-danger font-weight-bold">Required</p>
            )}
          </div>
        )}
        <Form.Label>
          Which year did you complete your A Level (or equivalent)
          qualifications? <span className="required-text"> *</span>
        </Form.Label>
        <Form.Control
          ref={register({ required: true })}
          as="select"
          name="alevel_year"
          onChange={e =>
            setCommonLayoutData({ ...commonLayoutData, ...getValues() })
          }
        >
          <option value="" selected>
            Select A Level (or equivalent) completion year
          </option>
          {yearsList.map((x, index) => (
            <option
              value={x.id}
              key={index}
              selected={commonLayoutData?.alevel_year == x}
            >
              {" "}
              {x}{" "}
            </option>
          ))}
        </Form.Control>
        {errors.alevel_year && (
          <p className="mb-0 text-danger font-weight-bold">
            Please select your A Level (or equivalent) completion year
          </p>
        )}
        <h4 className="mb-0 mt-4">BACKGROUND INFORMATION</h4>
        <Form.Label>
          How many different UK schools/colleges did you attend between the ages
          of 11 and 18 (school years 7-13 or equivalent)?
          <span className="required-text"> *</span>
          <p className="mb-0">
            <small>
              Note: If you attended the same school for your GCSEs and A-Levels
              please select 2 and simply write out the name of your school
              twice.
            </small>
          </p>
        </Form.Label>
        <Form.Control
          as="select"
          name="schools_and_colleges_attended"
          ref={register({ required: true })}
          onChange={e => {
            setEduData(Number(e.target.value));
            setCommonLayoutData({ ...commonLayoutData, ...getValues() });
          }}
          defaultValue={
            commonLayoutData !== null
              ? commonLayoutData.schools_and_colleges_attended
              : ""
          }
        >
          <option value="" selected>
            Select number of schools
          </option>
          {ranges.map((x, index) => (
            <option
              value={x.id}
              key={index}
              selected={commonLayoutData?.schools_and_colleges_attended == x}
            >
              {x}
            </option>
          ))}
        </Form.Control>
        {errors.schools_and_colleges_attended && (
          <p className="mb-0 text-danger font-weight-bold">
            Please enter the year you completed your GCSE studies
          </p>
        )}
        {getEduData >= 1 && (
          <div>
            <Form.Label>
              Please list all the UK schools/colleges you attended between the
              ages of 11 and 18 (school years 7-13 or equivalent)
            </Form.Label>
            <Form.Label>
              What school did you complete your GCSEs (or equivalent) at?
              <span className="required-text"> *</span>
            </Form.Label>
            <Form.Control
              autoComplete="off"
              type="text"
              ref={register({ required: true })}
              name="gcse_school_text"
              id="gcse_school_text"
              defaultValue={commonLayoutData?.gcse_school_text}
              onChange={e => {
                e.target.value.length > 2 ? filterGcseSchoolCode(e.target.value) : setGcseSchoolTextSuggestions(null);
                  if(e.target.value.length > 2){
                    commonLayoutData["gcse_school"] = "";
                    document.querySelector("#gcse_school").value = "";
                  }
              }}
            />
            <Form.Control
              type="hidden"
              ref={register}
              name="gcse_school"
              id="gcse_school"
            />
            {gcseSchoolTextSuggestions != null &&
              gcseSchoolTextSuggestions.length > 0 && (
                <div className="autocomplete_sugestions shadow p-3">
                  {gcseSchoolTextSuggestions.map(x => (
                    <div>
                      <a
                        href={x.id}
                        className="pb-1 text-reset text-decoration-none"
                        onClick={e => {
                          e.preventDefault();
                          document.querySelector("#gcse_school_text").value = x.value;
                          document.querySelector("#gcse_school").value = x.id;
                          commonLayoutData["gcse_school_text"] = x.value;
                          commonLayoutData["gcse_school"] = x.id;
                          setGcseSchoolTextSuggestions(null);
                        }}
                      >
                        {x.value}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            {errors.gcse_school_text && (
              <p className="mb-0 text-danger font-weight-bold">
                Please enter your GCSE School
              </p>
            )}
          </div>
        )}
        {getEduData >= 2 && (
          <div>
            <Form.Label>
              What school/college did you complete your A-Levels (or
              equivalent) at? <span className="required-text"> *</span>
            </Form.Label>
            <Form.Control
              autoComplete="off"
              type="text"
              ref={register({ required: true })}
              name="a_level_infos_text"
              id="a_level_infos_text"
              onChange={e => {
                e.target.value.length > 2 ? filterALevelInfos(e.target.value) : setALevelInfosTextSuggestions(null);
                  if(e.target.value.length > 2){
                    commonLayoutData["a_level_school"] = "";
                    document.querySelector("#a_level_school").value = "";
                  }
              }}
              defaultValue={commonLayoutData?.a_level_infos_text}
            />
            <Form.Control
              type="hidden"
              ref={register}
              name="a_level_school"
              id="a_level_school"
            />
            {aLevelInfosTextSuggestions != null &&
              aLevelInfosTextSuggestions.length > 0 && (
                <div className="autocomplete_sugestions shadow p-3">
                  {aLevelInfosTextSuggestions.map(x => (
                    <div>
                      <a
                        href=""
                        className="pb-1 text-reset text-decoration-none"
                        onClick={e => {
                          e.preventDefault();
                          document.querySelector("#a_level_infos_text").value =
                            x.value;
                          document.querySelector("#a_level_school").value = x.id;
                          commonLayoutData["a_level_infos_text"] = x.value;
                          commonLayoutData["a_level_school"] = x.id;
                          setALevelInfosTextSuggestions(null);
                        }}
                      >
                        {x.value}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            {errors.a_level_infos_text && (
              <p className="mb-0 text-danger font-weight-bold">
                Please enter your A-Level School
              </p>
            )}
          </div>
        )}
        {getEduData >= 3 && (
          <div>
            <Form.Label>
              Name of school/college <span className="required-text"> *</span>
            </Form.Label>
            <Form.Control
              autoComplete="off"
              type="text"
              ref={register({ required: true })}
              name="uk_school_three"
              id="uk_school_three"
              onChange={e => {
                let existingValue = document.querySelectorAll("#uk_school_three")[0].getAttribute("data-object")
                existingValue = existingValue && JSON.parse(existingValue)
                let val = e.target.value.replace(/ /g, "")
                val.length > 2 ? filterUkSchoolThree(e.target.value) : setFilterUkSchoolThreeSuggestions(null);
                if(existingValue){
                  let trimedVal = existingValue.value.replace(/ /g, "")
                  if(val !== trimedVal){
                    document.querySelector("#uk_school_three").setAttribute("data-object", "");
                  }
                }else if(val.length > 2){
                  document.querySelector("#uk_school_three").setAttribute("data-object", "");
                }
              }}
              defaultValue={commonLayoutData?.uk_school_three?.value}
            />
            {filterUkSchoolThreeSuggestions != null &&
              filterUkSchoolThreeSuggestions.length > 0 && (
                <div className="autocomplete_sugestions shadow p-3">
                  {filterUkSchoolThreeSuggestions.map(x => (
                    <div>
                      <a
                        href=""
                        className="pb-1 text-reset text-decoration-none"
                        value={x.id}
                        onClick={e => {

                          e.preventDefault();
                          document.querySelector("#uk_school_three").setAttribute("data-object", JSON.stringify(x));
                          document.querySelector("#uk_school_three").value = x.value;
                          setFilterUkSchoolThreeSuggestions(null);
                        }}
                      >
                        {x.value}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            {errors.uk_school_three && (
              <p className="mb-0 text-danger font-weight-bold">
                Please enter your school/college name
              </p>
            )}
          </div>
        )}
        {getEduData >= 4 && (
          <div>
            <Form.Label>
              Name of school/college <span className="required-text"> *</span>
            </Form.Label>
            <Form.Control
              autoComplete="off"
              type="text"
              ref={register({ required: true })}
              name="uk_school_four"
              id="uk_school_four"
              onChange={e => {
                let existingValue = document.querySelectorAll("#uk_school_four")[0].getAttribute("data-object")
                existingValue = existingValue && JSON.parse(existingValue)
                let val = e.target.value.replace(/ /g, "")
                val.length > 2 ? filterUkSchoolFour(e.target.value) : setFilterUkSchoolFourSuggestions(null);
                if(existingValue){
                  let trimedVal = existingValue.value.replace(/ /g, "")
                  if(val !== trimedVal){
                    document.querySelector("#uk_school_four").setAttribute("data-object", "");
                  }
                }else if(val.length > 2){
                  document.querySelector("#uk_school_four").setAttribute("data-object", "");
                }
              }}
              defaultValue={commonLayoutData?.uk_school_four?.value}
            />
            {filterUkSchoolFourSuggestions != null &&
              filterUkSchoolFourSuggestions.length > 0 && (
                <div className="autocomplete_sugestions shadow p-3">
                  {filterUkSchoolFourSuggestions.map(x => (
                    <div>
                      <a
                        href=""
                        className="pb-1 text-reset text-decoration-none"
                        value={x.id}
                        onClick={e => {
                          e.preventDefault();
                          document.querySelector("#uk_school_four").value = x.value;
                          document.querySelector("#uk_school_four").setAttribute("data-object", JSON.stringify(x));
                          setFilterUkSchoolFourSuggestions(null);
                        }}
                      >
                        {x.value}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            {errors.uk_school_four && (
              <p className="mb-0 text-danger font-weight-bold">
                Please enter your school/college name
              </p>
            )}
          </div>
        )}
        {getEduData >= 5 && (
          <div>
            <Form.Label>
              {" "}
              Name of school/college <span className="required-text">
                {" "}
                *
              </span>{" "}
            </Form.Label>
            <Form.Control
              autoComplete="off"
              type="text"
              ref={register({ required: true })}
              name="uk_school_five"
              id="uk_school_five"
              onChange={e => {
                let existingValue = document.querySelectorAll("#uk_school_five")[0].getAttribute("data-object")
                existingValue = existingValue && JSON.parse(existingValue)
                let val = e.target.value.replace(/ /g, "")
                val.length > 2 ? filterUkSchoolFive(e.target.value) : setFilterUkSchoolFiveSuggestions(null);
                if(existingValue){
                  let trimedVal = existingValue.value.replace(/ /g, "")
                  if(val !== trimedVal){
                    document.querySelector("#uk_school_five").setAttribute("data-object", "");
                  }
                }else if(val.length > 2){
                  document.querySelector("#uk_school_five").setAttribute("data-object", "");
                }
              }}
              defaultValue={commonLayoutData?.uk_school_five?.value}
            />
            {filterUkSchoolFiveSuggestions != null &&
              filterUkSchoolFiveSuggestions.length > 0 && (
                <div className="autocomplete_sugestions shadow p-3">
                  {filterUkSchoolFiveSuggestions.map(x => (
                    <div>
                      <a
                        href=""
                        className="pb-1 text-reset text-decoration-none"
                        value={x.id}
                        onClick={e => {
                          e.preventDefault();
                          document.querySelector("#uk_school_five").value = x.value;
                          document.querySelector("#uk_school_five").setAttribute("data-object", JSON.stringify(x));
                          setFilterUkSchoolFiveSuggestions(null);
                        }}>
                        {x.value}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            {errors.uk_school_five && (
              <p className="mb-0 text-danger font-weight-bold">
                Please enter your school/college name
              </p>
            )}
          </div>
        )}
        <Form.Label>
          What was your home postcode during your studies for your A Levels (or
          equivalent)? Please start typing your postcode, and select from the
          dropdown below.
          <span className="required-text"> *</span>
        </Form.Label>
        <Form.Control
          ref={register({ required: true })}
          autoComplete="off"
          type="text"
          name="postal_code_a_level"
          id="postal_code_a_level"
          placeholder="e.g. NW64DN"
          onChange={e => {
            e.target.value.length > 2
              ? filterHomePostCode(e.target.value)
              : setHomePostCodeSuggestions(null);
              if(e.target.value.length > 2){
                commonLayoutData["postal_code_a_level"] = "";
                document.querySelector("#postal_code_a_level").setAttribute("data-object", "");
              }
          }}
          defaultValue={commonLayoutData?.postal_code_a_level?.value}
        />
        {homePostCodeSuggestions != null && homePostCodeSuggestions.length > 0 && (
          <div className="autocomplete_sugestions shadow p-3">
            {homePostCodeSuggestions.map(x => (
              <div>
                <a
                  href=""
                  className="pb-1 text-reset text-decoration-none"
                  onClick={e => {
                    e.preventDefault();
                    document.querySelector("#postal_code_a_level").value =
                      x.value;
                    document
                      .querySelector("#postal_code_a_level")
                      .setAttribute("data-object", JSON.stringify(x));
                    setHomePostCodeSuggestions(null);
                  }}
                >
                  {x.value}
                </a>
              </div>
            ))}
          </div>
        )}
        {errors.postal_code_a_level && (
          <p className="mb-0 text-danger font-weight-bold">
            Please enter your home Postcode
          </p>
        )}
        <Form.Label>
          If different, please provide the home postcode for where you mostly
          lived when you studied for your GCSEs (or equivalent)
        </Form.Label>
        <Form.Control
          type="text"
          ref={register({ required: false })}
          autoComplete="off"
          name="postal_code_gcse"
          id="postal_code_gcse"
          placeholder="e.g. NW64DN"
          onChange={e => {
            e.target.value.length > 2
              ? filterALevelPostCode(e.target.value)
              : setALevelCodeSuggestions(null);
          }}
          defaultValue={commonLayoutData?.postal_code_gcse?.value}
        />
        {aLevelCodeSuggestions != null && aLevelCodeSuggestions.length > 0 && (
          <div className="autocomplete_sugestions shadow p-3">
            {aLevelCodeSuggestions.map(x => (
              <div>
                <a
                  href=""
                  className="pb-1 text-reset text-decoration-none"
                  onClick={e => {
                    e.preventDefault();
                    document.querySelector("#postal_code_gcse").value = x.value;
                    document
                      .querySelector("#postal_code_gcse")
                      .setAttribute("data-object", JSON.stringify(x));
                    setALevelCodeSuggestions(null);
                  }}
                >
                  {x.value}
                </a>
              </div>
            ))}
          </div>
        )}
        <Form.Label>
          If you have any mitigating circumstances that affected any of the academic results listed above, please provide further details.
        </Form.Label>
        <Form.Control
          type="text"
          ref={register({ required: false })}
          name="main_qualification_details.mitigating_circumstances"
          placeholder="Enter details on your mitigating circumstances"
          rows={3}
          onChange={e => {
            setValue("mitigating_circumstances", e.target.value)
          }
          }
          defaultValue={
            commonLayoutData !== null
              ? commonLayoutData?.main_qualification_details
                ?.mitigating_circumstances
              : ""
          }
        />
        <Form.Label>
          What is your current household income?
          <span className="required-text"> *</span>
        </Form.Label>
        <Form.Control
          as="select"
          id="household_income"
          ref={register({ required: true })}
          name="household_income"
          onChange={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }) }}
          value={commonLayoutData?.household_income}>
          <option value="" selected>
            Select household income
          </option>
          <option value="BELOW">Below £25,000</option>
          <option value="BETWEEN">Between £25,000 and £42,620</option>
          <option value="ABOVE">Above £42,620</option>
          <option value="DONT_KNOW">Don't Know</option>
          <option value="WOULD_RATHER_NOT_SAY">Would rather not say</option>
        </Form.Control>
        {errors.household_income && (
          <p className="mb-0 text-danger font-weight-bold">
            Please select your household income
          </p>
        )}
        <Form.Group>
          <Form.Check
            ref={register({ required: true })}
            name="form1_eligibility"
            style={{ display: "inline-block" }}
            id="eligibility"
            htmlFor="eligibility"
            onChange={() => {
              setEligibility(!eligibility);
              setCommonLayoutData({ ...commonLayoutData, ...getValues() });
            }}
            checked={eligibility}
            label={
              <>
                By ticking this box, I confirm that to the best of my knowledge
                I meet upReach’s eligibility criteria
                <a
                  href="https://upreach.org.uk/students#row-who-we-support"
                  target="_blank"
                  className="checkbox-link ml-2"
                >
                  (Who we support)
                </a>
              </>
            }
          />
          {errors.form1_eligibility && (
            <p className="mb-0 text-danger font-weight-bold">Required field</p>
          )}
        </Form.Group>
        {error !== "" && <div className="fail-text">{error}</div>}
        <div className="row text-center">
          <div className="col-lg-2 text-lg-left order-lg-first order-last">
            <Button
              onClick={(e) => {
                SavingDataObject(commonLayoutData);
                setStep(3);
                setProgressValue(40);
              }}
              className="submit-form mt-3 p-2"
              type="button"
            >
              BACK
            </Button>
          </div>
          <div className="col-lg-10 text-lg-right">
            <button onClick={(e) => {
              e.preventDefault();
              saveForLater(4, 60);
            }} className="mr-lg-5 mr-md-4 cancel-btn btn">
              SAVE FOR LATER
            </button>
            <Button className="submit-form mt-3 p-2" type="submit">
              NEXT
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};

export default ApplyNowFourthForm;
